import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

export const PageTemplate = ({
  title,
  description,
  content,
  seoTitle,
  twitterTitle,
  ogTitle,
  twitterDescription,
  ogDescription,
}) => {
  return (
    <div className="page">
      <Helmet title={`${title} | Efficiency of Movement`}>
        <meta name="description" content={description} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
      </Helmet>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h2>
          </div>
          <div
            className="content cell small-12"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string,
  seoTitle: PropTypes.string,
  twitterTitle: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  twitterDescription: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <PageTemplate
        title={page.title}
        description={data.site.siteMetadata.description}
        content={page.content}
        seoTitle={page.yoast_meta.yoast_wpseo_title}
        twitterTitle={page.yoast_meta.yoast_wpseo_twitter_title}
        ogTitle={page.yoast_meta.yoast_wpseo_opengraph_title}
        twitterDescription={page.yoast_meta.yoast_wpseo_twitter_description}
        ogDescription={page.yoast_meta.yoast_wpseo_opengraph_description}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        description
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_opengraph_image
        yoast_wpseo_metadesc
      }
    }
  }
`
